interface Props {
  children: React.ReactNode;
  title: string;
}

const AuthLayout = ({ children, title }: Props) => (
  <div className="h-screen  flex justify-center items-center">
    <div className="bg-blurry p-10 rounded-lg w-full" style={{ maxWidth: 400 }}>
      <div className="flex justify-between items-center mb-5">
        <h1 className="font-bold text-2xl text-yellow-500">{title}</h1>
        <img src="/logo.png" alt="" className="w-10" />
      </div>
      {children}
    </div>
  </div>
);

export default AuthLayout;
