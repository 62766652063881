import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FaUserPlus } from "react-icons/fa";
import { Link, Navigate } from "react-router-dom";
import { z } from "zod";
import authService from "../auth/authService";
import useAuthContext from "../auth/useAuthContext";
import AuthLayout from "../components/AuthLayout";
import Button from "../components/Button";

const validationSchema = z.object({
  username: z.string().length(10, "کد ملی باید ۱۰ رقم باشد"),
  password: z.string().min(5, "گذرواژه باید حداقل ۵ کاراکتر باشد"),
});

type FormData = z.infer<typeof validationSchema>;

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: zodResolver(validationSchema) });

  const onSubmit = async (data: FormData) => {
    const { username, password } = data;
    authService.register(username, password);
  };

  const { user } = useAuthContext();
  if (user) return <Navigate to="/" />;

  return (
    <AuthLayout title="ایجاد حساب جدید">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 flex flex-col space-y-1">
          <label htmlFor="username" className="text-gray-600 font-bold mr-1">
            کد ملی
          </label>
          <input
            id="username"
            type="text"
            dir="ltr"
            placeholder="۲۴۷۹۴۸۵۷۳۸"
            className="text-input"
            {...register("username")}
          />
          <p className="text-red-500 text-xs">{errors.username?.message}</p>
        </div>

        <div className="mb-4 flex flex-col space-y-1">
          <label htmlFor="password" className="text-gray-600 font-bold mr-1">
            گذرواژه
          </label>
          <input
            id="password"
            type="password"
            dir="ltr"
            placeholder="********"
            className="text-input"
            {...register("password")}
          />
          <p className="text-red-500 text-xs">{errors.password?.message}</p>
        </div>
        <div className="flex justify-between items-center">
          <Button label="ارسال" Icon={FaUserPlus} type="submit" />
          <Link to="/login" className="outline-button">
            ورود به حساب کاربری
          </Link>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Register;
