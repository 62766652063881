import { BiLoaderAlt } from "react-icons/bi";
interface Props {
  children: React.ReactNode;
  isLoading: boolean;
}

const Loading = ({ children, isLoading }: Props) => {
  if (!isLoading) return <>{children}</>;

  return (
    <div className="w-full h-full flex justify-center items-center bg-gray-500/5">
      <BiLoaderAlt className="spinner text-yellow-500" size={40} />
    </div>
  );
};

export default Loading;
