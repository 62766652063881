import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="h-screen bg-blurry text-center w-full max-w-lg py-8 text-yellow-500 font-bold flex flex-col justify-center">
    <h1 className="text-9xl font-sans">404</h1>
    <h2 className="text-xl">صفحه پیدا نشد</h2>
    <div className="mt-5">
      <Link to="/" className="outline-button">
        صفحه اصلی
      </Link>
    </div>
  </div>
);

export default NotFound;
