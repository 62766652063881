import { useEffect, useState } from "react";
import AuthContext, { User } from "./AuthContext";
import authService from "./authService";

interface Props {
  children: React.ReactNode;
}

const AuthContextProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setUser(authService.restoreUser());
    setIsLoading(false);
  }, []);

  if (isLoading) return null;

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
