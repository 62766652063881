import axios from "axios";
import authStorage from "./auth/authStorage";

const token = authStorage.getToken();
if (token) axios.defaults.headers.common["x-auth-token"] = token;

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export default apiClient;
