import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import apiClient from "../apiClient";
import { User } from "./AuthContext";
import authStorage from "./authStorage";

const authService = {
  register(username: string, password: string) {
    apiClient
      .post("/users", { username, password })
      .then(() => this.login(username, password))
      .catch((err) => {
        if (err.response?.status === 400)
          toast.error("کد ملی قبلا ثبت شده است");
      });
  },

  login(username: string, password: string) {
    apiClient
      .post<{ token: string }>("/auth", { username, password })
      .then((res) => {
        const { token } = res.data;
        authStorage.storeToken(token);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response?.status === 400)
          toast.error("کد ملی یا گذرواژه اشتباه است");
      });
  },

  logout() {
    authStorage.removeToken();
    window.location.reload();
  },

  restoreUser(): User | null {
    const token = authStorage.getToken();
    if (token) return jwtDecode<User>(token);

    return null;
  },
};

export default authService;
