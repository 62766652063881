import Balance from "../components/Balance";
import Navbar from "../components/Navbar";

const Home = () => (
  <div>
    <Navbar />
    <div
      className="container mx-auto rounded p-4 mt-4 h-full bg-blurry"
      style={{ height: 500 }}
    >
      <Balance />
    </div>
  </div>
);

export default Home;
