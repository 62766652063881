import { createContext } from "react";

export interface User {
  username: string;
  isAdmin: boolean;
}

interface AuthContextType {
  user: User | null;
  setUser(user: User | null): void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export default AuthContext;
