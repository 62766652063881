import { useEffect, useState } from "react";
import apiClient from "../apiClient";
import Loading from "./Loading";

const Balance = () => {
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiClient
      .get("/me")
      .then((res) => {
        setBalance(res.data.balance);
      })
      .finally(() => setIsLoading(false));
  });

  return (
    <div>
      <Loading isLoading={isLoading}>
        <p>
          موجودی:{" "}
          <span className="text-yellow-500 font-bold text-xl">{balance}</span>
        </p>
      </Loading>
    </div>
  );
};

export default Balance;
