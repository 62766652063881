import classNames from "classnames";
import { IconType } from "react-icons";

interface Props {
  label?: string;
  Icon?: IconType;
  type?: "submit" | "button";
}

const Button = ({ label, Icon, type = "button" }: Props) => (
  <button
    className={classNames(
      "bg-yellow-500 text-white font-bold",
      "rounded flex items-center px-4 py-2",
      "hover:opacity-80 transition-opacity"
    )}
    type={type}
  >
    {Icon && <Icon className="ml-2" size={20} />}
    <span>{label}</span>
  </button>
);

export default Button;
