import { FaSignOutAlt } from "react-icons/fa";
import authService from "../auth/authService";
import useAuthContext from "../auth/useAuthContext";

const Navbar = () => {
  const { user } = useAuthContext();
  if (!user) alert();

  return (
    <div className="h-12 bg-blurry px-3 p-1 flex justify-between items-center">
      <div>
        <button
          className="outline-button flex items-center"
          onClick={authService.logout}
        >
          <FaSignOutAlt className="ml-1" />
          <span>خروج</span>
        </button>
      </div>
      <div className="h-full">
        <img src="/logo.png" alt="logo" className="h-full" />
      </div>
    </div>
  );
};

export default Navbar;
